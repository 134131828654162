<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="6">
                <v-row>
                    <v-col cols="12" class="py-0">
                        <cp-text-field   
                            ref="expires_in_field"
                            :label="$t('forms.CalculationRuleForm.expires_in.label')"
                            :hint="$t('forms.CalculationRuleForm.expires_in.hint')"
                            :placeholder="$t('forms.CalculationRuleForm.expires_in.placeholder')"
                            required
                            :error-messages="getErrorMessages('expires_in')"
                            v-model="value.expires_in"
                            @input="clearErrorMessages('expires_in')"
                        />
                    </v-col>

                    <v-col cols="12" class="py-0">
                        <cp-text-field
                            ref="in_ratio_field"
                            :label="$t('forms.CalculationRuleForm.in_ratio.label')"
                            :hint="$t('forms.CalculationRuleForm.in_ratio.hint')"
                            :placeholder="$t('forms.CalculationRuleForm.in_ratio.placeholder')"
                            required
                            :error-messages="getErrorMessages('in_ratio')"
                            v-model="value.in_ratio"
                            @input="clearErrorMessages('in_ratio')"
                        />
                    </v-col>

                    <v-col cols="12" class="py-0">
                        <cp-text-field
                            ref="out_ratio_field"
                            :label="$t('forms.CalculationRuleForm.out_ratio.label')"
                            :hint="$t('forms.CalculationRuleForm.out_ratio.hint')"
                            :placeholder="$t('forms.CalculationRuleForm.out_ratio.placeholder')"
                            required
                            :error-messages="getErrorMessages('out_ratio')"
                            v-model="value.out_ratio"
                            @input="clearErrorMessages('out_ratio')"
                            @keyup.enter="$emit('triggerSubmit')"
                        />
                    </v-col>
                </v-row>
            </v-col>    

            <v-col cols="12" xs="12" sm="12" md="6" class="pb-0">
                <v-row>
                    <div class="mx-auto mt-0 mt-md-6 pa-6 pb-4 preview" :style="{ backgroundColor: `${primaryColor}15` }">
                        <v-col cols="12" class="pt-0 text-center" align-self="center">
                            <div>
                                <div class="primary_font--text">{{ $t('forms.CalculationRuleForm.in_out_hint.step1.description1') }}</div>
                                <div class="primary--text preview-values">{{ $t('forms.CalculationRuleForm.in_out_hint.step1.description2') }}</div>
                                <div class="primary_font--text">{{ $t('forms.CalculationRuleForm.in_out_hint.step1.description3', { type: type_name }) }}</div>
                            </div>
                        </v-col>
                        <v-col cols="12" class="pt-0 text-center" align-self="center">
                            <v-icon :size="25" color="primary" class="my-2">fas fa-chevron-down</v-icon>
                        </v-col>
                        <v-col cols="12" class="pt-0 text-center" align-self="center">
                            <div>
                                <div class="primary_font--text">{{ $t('forms.CalculationRuleForm.in_out_hint.step2.description1') }}</div>
                                <div class="primary--text preview-values">{{ step2Description2() }}</div>
                                <div class="primary_font--text">{{ $t('forms.CalculationRuleForm.in_out_hint.step2.description3') }}</div>
                            </div>
                        </v-col>
                        <v-col cols="12" class="py-0 text-center" align-self="center">
                            <div v-if="disableCashback" class="primary--text">
                                {{ $t('forms.CalculationRuleForm.in_out_hint.step3_disable_cashback.description', { value: step3Description() }) }}
                            </div>
                            <div v-else class="primary--text">
                                {{ $t('forms.CalculationRuleForm.in_out_hint.step3.description', { value: step3Description() }) }}
                            </div>
                        </v-col>
                    </div>
                </v-row>
            </v-col>

            <v-col cols="12" class="pt-0">
                <v-row>
                    <v-col cols="12" class="pt-6 pb-0 pt-md-0">
                        <v-switch v-model="useLimits" @change="setLimits" :label="$t('forms.CalculationRuleForm.usage_hint.title')"></v-switch>
                    </v-col>

                    <template v-if="useLimits">
                        <v-col cols="12" class="pt-0">
                            <p v-html="$t('forms.CalculationRuleForm.usage_hint.description1')"></p>
                            <p v-html="$t('forms.CalculationRuleForm.usage_hint.description2')"></p>
                        </v-col>

                        <v-col cols="12" class="py-0">
                            <cp-text-field
                                ref="usage_limit_field"
                                :label="$t('forms.CalculationRuleForm.usage_limit.label')"
                                :hint="$t('forms.CalculationRuleForm.usage_limit.hint')"
                                :placeholder="$t('forms.CalculationRuleForm.usage_limit.placeholder')"
                                :suffix="$t('forms.CalculationRuleForm.usage_limit.suffix')"
                                required
                                :error-messages="getErrorMessages('usage_limit')"
                                v-model="value.usage_limit"
                                @input="clearErrorMessages('usage_limit')"
                            />
                        </v-col>

                        <v-col cols="12" class="py-0">
                            <cp-text-field
                                ref="usage_period_field"
                                :label="$t('forms.CalculationRuleForm.usage_period.label')"
                                :hint="$t('forms.CalculationRuleForm.usage_period.hint')"
                                :placeholder="$t('forms.CalculationRuleForm.usage_period.placeholder')"
                                :suffix="$t('forms.CalculationRuleForm.usage_period.suffix')"
                                required
                                :error-messages="getErrorMessages('usage_period')"
                                @input="clearErrorMessages('usage_period')"
                                v-model="value.usage_period"
                                @keyup.enter="$emit('triggerSubmit')"
                            />
                        </v-col>
                    </template>
                </v-row>
            </v-col>
        </v-row>   
            
        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

    </v-form>
</template>

<script>
import calculationRule from '@/models/CalculationRule'
import FormMixin       from '@/mixins/FormMixin'
import { mapGetters }  from 'vuex'

export default {
    name: 'CalculationRuleForm',
    mixins: [ FormMixin(calculationRule, 'expires_in_field') ],
    props: {
        calculationRule: Object,
        formType: String,
    },
    data: vm => {
        return {
            useLimits: false,
            useKey: false,
            type_name: '',
        }
    },
    created() {
        this.useLimits = Number(this.value.usage_limit) || Number(this.value.usage_period)
    },
    methods: {
        onTypeInput(type = {}) {
            this.value.type_id = type.id
            this.type_name = type.name
        },
        setLimits(value) {
            if (value)
                return

            delete this.value.usage_limit
            delete this.value.usage_period
        },
        step2Description2() {
            return this.$tc('forms.CalculationRuleForm.in_out_hint.step2.description2', Number(this.value.in_ratio))
        },
        step3Description() {
            return this.$options.filters.currency(this.final_out)
        },
    },
    computed: {
        ...mapGetters({
            primaryColor: 'company/primary_color',
            disableCashback: 'company/disable_cashback',
        }),
        final_out() {
            return !this.value.hasErrors(['in_ratio', 'out_ratio']) ? this.$lodash.round(this.value.in_ratio * this.value.out_ratio, 2) : 0
        },
    }
}
</script>

<style lang="scss">
.preview {
    border: solid 1px var(--v-primary-base);
    border-radius: $card-border-radius;
}

.preview-values {
    font-size: 24px;
}
</style>