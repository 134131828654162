<template>
    <v-container fluid class="pt-0">
        <CreateModal ref="create_modal" :type="selectedType" :calculationRule="selectedCalculationRule" />
        <DetailsModal ref="details_modal" :type="selectedType" :calculationRule="selectedCalculationRule" @editCLR="handleEditCLR" />
        <EditModal ref="edit_modal" :type="selectedType" :calculationRule="selectedCalculationRule" @editCLR="handleEditCLR" />
        <EditDefaultModal ref="edit_default_modal" :calculationRule="selectedCalculationRule" />

        <span class="pl-5 pl-md-12 section-title">{{ $t('views.types.title') }}</span>
        <v-alert v-if="hasError" type="error" class="mt-4 mx-5 mt-md-6 mx-md-12">
            {{ $t('errors.generic') }}
        </v-alert>
        <TypesTable 
            v-else
            ref="table"
            class="my-4 mx-2 mx-md-10" 
            :fetchFunction="fetchFunction"
            @create="openCreate" 
            @details="openDetails" 
            @edit="openEdit"
            @delete="handleDelete"
        />
    </v-container>
</template>

<script>
import EditDefaultModal     from '@/components/modals/types/EditDefaultModal'
import DetailsModal         from '@/components/modals/types/DetailsModal'
import CreateModal          from '@/components/modals/types/CreateModal'
import EditModal            from '@/components/modals/types/EditModal'
import TypesTable           from '@/components/tables/TypesTable'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import CalculationRule      from '@/models/CalculationRule'
import Type                 from '@/models/Type'

export default {
    data: () => ({
        selectedType           : new Type,
        selectedCalculationRule: new CalculationRule(),
        hasError               : false,
    }),
    mixins: [ HasErrorHandlerMixin ],
    components: { TypesTable, CreateModal, EditModal, EditDefaultModal, DetailsModal },
    methods: {
        async openCreate() {
            this.selectedType = new Type()
            this.selectedCalculationRule = new CalculationRule()
            let r = await this.$refs.create_modal.open()
            if (!r) 
                return

            this.$bus.$emit('message', this.$t('views.types.List.alerts.create_success'), 'success')
            await this.refreshTable()
        },
        async openDetails(type) {
            this.selectedType = type

            this.loading = true
            this.loadingPrev = true
            this.loadingNext = true

            let hasError
            let response = await this.$axios.get(`/calculation-rule/${this.selectedType.id}`)
                .catch(async err => {
                    this.loading = false
                    this.loadingPrev = false
                    this.loadingNext = false

                    let errType
                    if (err.message == 'Network Error')
                        errType = 'network_error'
                    else if (err.response.status == 404)
                        return
                    else if (err.response.data.type)
                        errType = err.response.data.type
                    else
                        errType = 'error'

                    hasError = true
                    const definedErrors = [
                        'network_error',
                    ]
                    errType = definedErrors.includes(errType) ? errType : 'error'
                    await new Promise((resolve) => {
                        this.$bus.$emit('alert', this.$t(`views.types.Details.alerts.${errType}`), 'error', resolve)
                    })
                })

            this.loading = false
            this.loadingPrev = false
            this.loadingNext = false

            if (hasError)
                return

            this.selectedCalculationRule = response && response.data ? new CalculationRule(response.data) : null
            this.$refs.details_modal.open()
        },
        async openEdit(type, createCLR = false) {
            // Precisa criar uma nova instância
            this.selectedType = new Type(this.$lodash.cloneDeep(type))

            this.loading = true
            this.loadingPrev = true
            this.loadingNext = true

            let hasError
            let response = await this.$axios.get(`/calculation-rule/${this.selectedType.id}`)
                .catch(async err => {
                    this.loading = false
                    this.loadingPrev = false
                    this.loadingNext = false

                    let errType
                    if (err.message == 'Network Error')
                        errType = 'network_error'
                    else if (err.response.status == 404)
                        return
                    else if (err.response.data.type)
                        errType = err.response.data.type
                    else
                        errType = 'error_get_clr'

                    hasError = true
                    const definedErrors = [
                        'network_error',
                    ]
                    errType = definedErrors.includes(errType) ? errType : 'error_get_clr'
                    await new Promise((resolve) => {
                        this.$bus.$emit('alert', this.$t(`views.types.Edit.alerts.${errType}`), 'error', resolve)
                    })
                })

            this.loading = false
            this.loadingPrev = false
            this.loadingNext = false

            if (hasError)
                return

            this.selectedCalculationRule = response && response.data ? new CalculationRule(response.data) : createCLR ? new CalculationRule() : null
            
            // Se for o tipo default, abre a modal de edição somente da regra de cálculo
            let r
            if (this.selectedType.id == this.$constants.getConstant('DEFAULT_TYP_ID'))
                r = await this.$refs.edit_default_modal.open()
            else
                r = await this.$refs.edit_modal.open()

            if (!r) 
                return

            this.$bus.$emit('message', this.$t('views.types.List.alerts.edit_success'), 'success')
            await this.refreshTable()
        },
        async handleDelete(type) {
            // Deleta a regra de cálculo
            let calculationRule = new CalculationRule({ id: type.id })
            let response = await calculationRule.get()
                .catch(this.errorHandler)
            
            if (response && response.data) {
                calculationRule = new CalculationRule(this.$lodash.get(response, 'data', {}))
                await calculationRule.delete()
                    .catch(this.preErrorHandler)
            }

            if (this.hasError) {
                this.hasError = false
                return
            }

            // Deleta o tipo
            await type.delete()
                .catch(this.preErrorHandler)

            if (this.hasError) {
                this.hasError = false
                return
            }

            this.$bus.$emit('message', this.$t('views.types.List.alerts.delete_success'), 'success')
            await this.refreshTable()
        },
        async handleEditCLR(type) {
            await this.$refs.details_modal.close()
            await this.openEdit(type, true)
        },
        async refreshTable() {
            await this.$refs.table.refresh()
        },
        async fetchFunction(pagination) {
            this.hasError = false
            let response  = await Type.list(pagination, { show_default_voucher_type: 'true' })
                .catch(this.preErrorHandler)
            return Type.hydrate(this.$lodash.get(response, 'data', []))
        },
        preErrorHandler(e) {
            this.hasError = true
            this.errorHandler(e)
        },
        handleDeleted() {
            this.fetchData()    
        },
    }
}
</script>