var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.title,
        confirmButton: _vm.computedConfirmButton,
        cancelButton: _vm.computedCancelButton,
        "max-width": "570px"
      },
      on: { cancel: _vm.cancel, confirm: _vm.confirm },
      model: {
        value: _vm.isModalOpen,
        callback: function($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen"
      }
    },
    [
      _vm.isModalOpen
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c("cp-text-field", {
                    ref: "name_field",
                    attrs: {
                      label: _vm.$t("forms.TypeForm.name.label"),
                      readonly: "",
                      required: "",
                      type: "text"
                    },
                    model: {
                      value: _vm.type.name,
                      callback: function($$v) {
                        _vm.$set(_vm.type, "name", $$v)
                      },
                      expression: "type.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c("cp-text-field", {
                    ref: "key_field",
                    attrs: {
                      label: _vm.$t("forms.TypeForm.key.label"),
                      readonly: "",
                      required: "",
                      type: "text"
                    },
                    model: {
                      value: _vm.type.key,
                      callback: function($$v) {
                        _vm.$set(_vm.type, "key", $$v)
                      },
                      expression: "type.key"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("v-divider", { staticClass: "mb-4" }),
      _c("span", { staticClass: "primary--text" }, [
        _vm._v(_vm._s(_vm.$t("views.types.personalization")))
      ]),
      _vm.isModalOpen && _vm.calculationRule
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    xs: "12",
                    sm: "12",
                    md: "6",
                    clas: "mt-4"
                  }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("cp-text-field", {
                            ref: "expires_in_field",
                            attrs: {
                              label: _vm.$t(
                                "forms.CalculationRuleForm.expires_in.label"
                              ),
                              readonly: "",
                              required: ""
                            },
                            model: {
                              value: _vm.calculationRule.expires_in,
                              callback: function($$v) {
                                _vm.$set(_vm.calculationRule, "expires_in", $$v)
                              },
                              expression: "calculationRule.expires_in"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("cp-text-field", {
                            ref: "in_ratio_field",
                            attrs: {
                              label: _vm.$t(
                                "forms.CalculationRuleForm.in_ratio.label"
                              ),
                              readonly: "",
                              required: ""
                            },
                            model: {
                              value: _vm.calculationRule.in_ratio,
                              callback: function($$v) {
                                _vm.$set(_vm.calculationRule, "in_ratio", $$v)
                              },
                              expression: "calculationRule.in_ratio"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("cp-text-field", {
                            ref: "out_ratio_field",
                            attrs: {
                              label: _vm.$t(
                                "forms.CalculationRuleForm.out_ratio.label"
                              ),
                              readonly: "",
                              required: ""
                            },
                            model: {
                              value: _vm.calculationRule.out_ratio,
                              callback: function($$v) {
                                _vm.$set(_vm.calculationRule, "out_ratio", $$v)
                              },
                              expression: "calculationRule.out_ratio"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "pb-0",
                  attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
                },
                [
                  _c("v-row", [
                    _c(
                      "div",
                      {
                        staticClass: "mx-auto mt-0 mt-md-6 pa-6 pb-4 preview",
                        style: { backgroundColor: _vm.primaryColor + "15" }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "pt-0 text-center",
                            attrs: { cols: "12", "align-self": "center" }
                          },
                          [
                            _c("div", [
                              _c("div", { staticClass: "primary_font--text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "forms.CalculationRuleForm.in_out_hint.step1.description1"
                                    )
                                  )
                                )
                              ]),
                              _c(
                                "div",
                                { staticClass: "primary--text preview-values" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "forms.CalculationRuleForm.in_out_hint.step1.description2"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "primary_font--text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "forms.CalculationRuleForm.in_out_hint.step1.description3",
                                      { type: _vm.type_name }
                                    )
                                  )
                                )
                              ])
                            ])
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "pt-0 text-center",
                            attrs: { cols: "12", "align-self": "center" }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "my-2",
                                attrs: { size: 25, color: "primary" }
                              },
                              [_vm._v("fas fa-chevron-down")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "pt-0 text-center",
                            attrs: { cols: "12", "align-self": "center" }
                          },
                          [
                            _c("div", [
                              _c("div", { staticClass: "primary_font--text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "forms.CalculationRuleForm.in_out_hint.step2.description1"
                                    )
                                  )
                                )
                              ]),
                              _c(
                                "div",
                                { staticClass: "primary--text preview-values" },
                                [_vm._v(_vm._s(_vm.step2Description2()))]
                              ),
                              _c("div", { staticClass: "primary_font--text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "forms.CalculationRuleForm.in_out_hint.step2.description3"
                                    )
                                  )
                                )
                              ])
                            ])
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "py-0 text-center",
                            attrs: { cols: "12", "align-self": "center" }
                          },
                          [
                            _c("div", { staticClass: "primary--text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "forms.CalculationRuleForm.in_out_hint.step3.description",
                                    {
                                      value: _vm.step3Description(
                                        _vm.calculationRule.out_ratio
                                      )
                                    }
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pt-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _vm.useLimits
                        ? [
                            _c(
                              "v-col",
                              {
                                staticClass: "pt-6 pb-0",
                                attrs: { cols: "12" }
                              },
                              [
                                _c("cp-text-field", {
                                  ref: "usage_limit_field",
                                  attrs: {
                                    label: _vm.$t(
                                      "forms.CalculationRuleForm.usage_limit.label"
                                    ),
                                    suffix: _vm.$t(
                                      "forms.CalculationRuleForm.usage_limit.suffix"
                                    ),
                                    readonly: "",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.calculationRule.usage_limit,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.calculationRule,
                                        "usage_limit",
                                        $$v
                                      )
                                    },
                                    expression: "calculationRule.usage_limit"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { staticClass: "py-0", attrs: { cols: "12" } },
                              [
                                _c("cp-text-field", {
                                  ref: "usage_period_field",
                                  attrs: {
                                    label: _vm.$t(
                                      "forms.CalculationRuleForm.usage_period.label"
                                    ),
                                    suffix: _vm.$t(
                                      "forms.CalculationRuleForm.usage_period.suffix"
                                    ),
                                    readonly: "",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.calculationRule.usage_period,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.calculationRule,
                                        "usage_period",
                                        $$v
                                      )
                                    },
                                    expression: "calculationRule.usage_period"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-4 pt-1",
                      attrs: {
                        width: "100%",
                        depressed: "",
                        outlined: "",
                        color: "primary"
                      },
                      on: { click: _vm.editCalculationRule }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("views.types.Details.edit_calculation_rule")
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c("div", { staticClass: "mt-4" }, [
                    _vm._v(
                      _vm._s(_vm.$t("views.types.Details.no_calculation_rule"))
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }