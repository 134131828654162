var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseCrudTable", {
    ref: "table",
    attrs: {
      tableName: "TypesTable",
      headerConfigs: _vm.headerConfigs,
      fetchFunction: _vm.fetchFunction,
      pagination: _vm.pagination
    },
    on: {
      create: _vm.handleCreate,
      details: _vm.handleDetails,
      edit: _vm.handleEdit,
      delete: _vm.handleDelete
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }