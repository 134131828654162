var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.title,
        confirmButton: _vm.computedConfirmButton,
        cancelButton: _vm.computedCancelButton,
        "max-width": "570px",
        persistent: ""
      },
      on: { cancel: _vm.cancel, confirm: _vm.triggerSubmit },
      model: {
        value: _vm.isModalOpen,
        callback: function($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen"
      }
    },
    [
      !_vm.loading
        ? [
            _vm.isModalOpen
              ? _c("TypeForm", {
                  ref: "type_form",
                  attrs: {
                    isFormValid: _vm.isTypeFormValid,
                    formType: "CREATE",
                    loading: _vm.loading
                  },
                  on: {
                    "update:isFormValid": function($event) {
                      _vm.isTypeFormValid = $event
                    },
                    "update:is-form-valid": function($event) {
                      _vm.isTypeFormValid = $event
                    },
                    submit: _vm.submitType,
                    triggerSubmit: _vm.triggerSubmit
                  },
                  model: {
                    value: _vm.type,
                    callback: function($$v) {
                      _vm.type = $$v
                    },
                    expression: "type"
                  }
                })
              : _vm._e(),
            _c("v-divider", { staticClass: "mb-4" }),
            _c("span", { staticClass: "primary--text" }, [
              _vm._v(_vm._s(_vm.$t("views.types.personalization")))
            ]),
            _vm.isModalOpen
              ? _c("CalculationRuleForm", {
                  ref: "calculation_rule_form",
                  staticClass: "mt-4",
                  attrs: {
                    isFormValid: _vm.isCLRFormValid,
                    formType: "CREATE",
                    loading: _vm.loading
                  },
                  on: {
                    "update:isFormValid": function($event) {
                      _vm.isCLRFormValid = $event
                    },
                    "update:is-form-valid": function($event) {
                      _vm.isCLRFormValid = $event
                    },
                    submit: _vm.getCLR,
                    triggerSubmit: _vm.triggerSubmit
                  },
                  model: {
                    value: _vm.calculationRule,
                    callback: function($$v) {
                      _vm.calculationRule = $$v
                    },
                    expression: "calculationRule"
                  }
                })
              : _vm._e()
          ]
        : _c("span", [_vm._v(_vm._s(_vm.$t("forms.wait")))])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }