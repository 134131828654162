<template>
    <BaseModal ref="modal"
        @cancel="cancel" @confirm="confirm"
        v-model="isModalOpen"
        :title="title"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
        max-width="570px"
    >
        <v-row v-if="isModalOpen">
            <v-col cols="12" class="py-0">
                <cp-text-field   
                    ref="name_field"
                    :label="$t('forms.TypeForm.name.label')"
                    readonly required type="text"
                    v-model="type.name"
                />
            </v-col>
            
            <v-col cols="12" class="py-0">
                <cp-text-field
                    ref="key_field"
                    :label="$t('forms.TypeForm.key.label')"
                    readonly required type="text"
                    v-model="type.key"
                />
            </v-col>
        </v-row>

        <v-divider class="mb-4"></v-divider>
        <span class="primary--text">{{ $t('views.types.personalization') }}</span>

        <v-row v-if="isModalOpen && calculationRule">
            <v-col cols="12" xs="12" sm="12" md="6" clas="mt-4">
                <v-row>
                    <v-col cols="12" class="py-0">
                        <cp-text-field   
                            ref="expires_in_field"
                            :label="$t('forms.CalculationRuleForm.expires_in.label')"
                            readonly required
                            v-model="calculationRule.expires_in"
                        />
                    </v-col>

                    <v-col cols="12" class="py-0">
                        <cp-text-field
                            ref="in_ratio_field"
                            :label="$t('forms.CalculationRuleForm.in_ratio.label')"
                            readonly required
                            v-model="calculationRule.in_ratio"
                        />
                    </v-col>

                    <v-col cols="12" class="py-0">
                        <cp-text-field
                            ref="out_ratio_field"
                            :label="$t('forms.CalculationRuleForm.out_ratio.label')"
                            readonly required
                            v-model="calculationRule.out_ratio"
                        />
                    </v-col>
                </v-row>
            </v-col>    

            <v-col cols="12" xs="12" sm="12" md="6" class="pb-0">
                <v-row>
                    <div class="mx-auto mt-0 mt-md-6 pa-6 pb-4 preview" :style="{ backgroundColor: `${primaryColor}15` }">
                        <v-col cols="12" class="pt-0 text-center" align-self="center">
                            <div>
                                <div class="primary_font--text">{{ $t('forms.CalculationRuleForm.in_out_hint.step1.description1') }}</div>
                                <div class="primary--text preview-values">{{ $t('forms.CalculationRuleForm.in_out_hint.step1.description2') }}</div>
                                <div class="primary_font--text">{{ $t('forms.CalculationRuleForm.in_out_hint.step1.description3', { type: type_name }) }}</div>
                            </div>
                        </v-col>
                        <v-col cols="12" class="pt-0 text-center" align-self="center">
                            <v-icon :size="25" color="primary" class="my-2">fas fa-chevron-down</v-icon>
                        </v-col>
                        <v-col cols="12" class="pt-0 text-center" align-self="center">
                            <div>
                                <div class="primary_font--text">{{ $t('forms.CalculationRuleForm.in_out_hint.step2.description1') }}</div>
                                <div class="primary--text preview-values">{{ step2Description2() }}</div>
                                <div class="primary_font--text">{{ $t('forms.CalculationRuleForm.in_out_hint.step2.description3') }}</div>
                            </div>
                        </v-col>
                        <v-col cols="12" class="py-0 text-center" align-self="center">
                            <div class="primary--text">{{ $t('forms.CalculationRuleForm.in_out_hint.step3.description', { value: step3Description(calculationRule.out_ratio) }) }}</div>
                        </v-col>
                    </div>
                </v-row>
            </v-col>

            <v-col cols="12" class="pt-0">
                <v-row>
                    <template v-if="useLimits">
                        <v-col cols="12" class="pt-6 pb-0">
                            <cp-text-field
                                ref="usage_limit_field"
                                :label="$t('forms.CalculationRuleForm.usage_limit.label')"
                                :suffix="$t('forms.CalculationRuleForm.usage_limit.suffix')"
                                readonly required
                                v-model="calculationRule.usage_limit"
                            />
                        </v-col>

                        <v-col cols="12" class="py-0">
                            <cp-text-field
                                ref="usage_period_field"
                                :label="$t('forms.CalculationRuleForm.usage_period.label')"
                                :suffix="$t('forms.CalculationRuleForm.usage_period.suffix')"
                                readonly required
                                v-model="calculationRule.usage_period"
                            />
                        </v-col>
                    </template>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <v-btn width="100%" depressed outlined color="primary" class="mt-4 pt-1" @click="editCalculationRule">
                    {{ $t('views.types.Details.edit_calculation_rule') }}
                </v-btn>
                <div class="mt-4">{{ $t('views.types.Details.no_calculation_rule') }}</div>
            </v-col>
        </v-row>
    </BaseModal>
</template>

<script>
import BaseModal                    from '@/components/modals/BaseModal'
import CalculationRule              from '@/models/CalculationRule'
import BaseModalMixin               from '../BaseModalMixin'
import Type                         from '@/models/Type'
import { mapGetters }               from 'vuex'

export default {
    name: 'TypesDetailsModal',
    mixins: [ BaseModalMixin ],
    components: { BaseModal },
    props: {
        type: {
            type: Type,
            required: true,
        },
        calculationRule: {
            validation: value => {
                return !value || value.instanceof(CalculationRule)
            },
        },
    },
    data: vm => {
        return {
            title: vm.$t('views.types.Details.title'),
            useKey: false,
            type_name: '',
        }
    },
    methods: {
        editCalculationRule() {
            this.$emit('editCLR', this.type)
        },
        setLimits(value) {
            if (value)
                return

            delete this.calculationRule.usage_limit
            delete this.calculationRule.usage_period
        },
        step2Description2() {
            return this.$tc('forms.CalculationRuleForm.in_out_hint.step2.description2', Number(this.calculationRule.in_ratio))
        },
        step3Description() {
            return this.$options.filters.currency(this.final_out)
        },
    },  
    computed: {
        ...mapGetters({
            primaryColor: 'company/primary_color' 
        }),
        useLimits() {
            return Number(this.calculationRule.usage_limit) || Number(this.calculationRule.usage_period)
        },
        final_out() {
            return !this.calculationRule.hasErrors(['in_ratio', 'out_ratio']) ? this.$lodash.round(this.calculationRule.in_ratio * this.calculationRule.out_ratio, 2) : 0
        },
        computedConfirmButton() {
            return {
                label: this.$t('actions.close'),
            }
        },
        computedCancelButton() {
            return {
                hide: true,
            }
        },
    },
}
</script>