var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", xs: "12", sm: "12", md: "6" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-text-field", {
                        ref: "expires_in_field",
                        attrs: {
                          label: _vm.$t(
                            "forms.CalculationRuleForm.expires_in.label"
                          ),
                          hint: _vm.$t(
                            "forms.CalculationRuleForm.expires_in.hint"
                          ),
                          placeholder: _vm.$t(
                            "forms.CalculationRuleForm.expires_in.placeholder"
                          ),
                          required: "",
                          "error-messages": _vm.getErrorMessages("expires_in")
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("expires_in")
                          }
                        },
                        model: {
                          value: _vm.value.expires_in,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "expires_in", $$v)
                          },
                          expression: "value.expires_in"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-text-field", {
                        ref: "in_ratio_field",
                        attrs: {
                          label: _vm.$t(
                            "forms.CalculationRuleForm.in_ratio.label"
                          ),
                          hint: _vm.$t(
                            "forms.CalculationRuleForm.in_ratio.hint"
                          ),
                          placeholder: _vm.$t(
                            "forms.CalculationRuleForm.in_ratio.placeholder"
                          ),
                          required: "",
                          "error-messages": _vm.getErrorMessages("in_ratio")
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("in_ratio")
                          }
                        },
                        model: {
                          value: _vm.value.in_ratio,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "in_ratio", $$v)
                          },
                          expression: "value.in_ratio"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-text-field", {
                        ref: "out_ratio_field",
                        attrs: {
                          label: _vm.$t(
                            "forms.CalculationRuleForm.out_ratio.label"
                          ),
                          hint: _vm.$t(
                            "forms.CalculationRuleForm.out_ratio.hint"
                          ),
                          placeholder: _vm.$t(
                            "forms.CalculationRuleForm.out_ratio.placeholder"
                          ),
                          required: "",
                          "error-messages": _vm.getErrorMessages("out_ratio")
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("out_ratio")
                          },
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.$emit("triggerSubmit")
                          }
                        },
                        model: {
                          value: _vm.value.out_ratio,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "out_ratio", $$v)
                          },
                          expression: "value.out_ratio"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pb-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
            },
            [
              _c("v-row", [
                _c(
                  "div",
                  {
                    staticClass: "mx-auto mt-0 mt-md-6 pa-6 pb-4 preview",
                    style: { backgroundColor: _vm.primaryColor + "15" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "pt-0 text-center",
                        attrs: { cols: "12", "align-self": "center" }
                      },
                      [
                        _c("div", [
                          _c("div", { staticClass: "primary_font--text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "forms.CalculationRuleForm.in_out_hint.step1.description1"
                                )
                              )
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "primary--text preview-values" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "forms.CalculationRuleForm.in_out_hint.step1.description2"
                                  )
                                )
                              )
                            ]
                          ),
                          _c("div", { staticClass: "primary_font--text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "forms.CalculationRuleForm.in_out_hint.step1.description3",
                                  { type: _vm.type_name }
                                )
                              )
                            )
                          ])
                        ])
                      ]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "pt-0 text-center",
                        attrs: { cols: "12", "align-self": "center" }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "my-2",
                            attrs: { size: 25, color: "primary" }
                          },
                          [_vm._v("fas fa-chevron-down")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "pt-0 text-center",
                        attrs: { cols: "12", "align-self": "center" }
                      },
                      [
                        _c("div", [
                          _c("div", { staticClass: "primary_font--text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "forms.CalculationRuleForm.in_out_hint.step2.description1"
                                )
                              )
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "primary--text preview-values" },
                            [_vm._v(_vm._s(_vm.step2Description2()))]
                          ),
                          _c("div", { staticClass: "primary_font--text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "forms.CalculationRuleForm.in_out_hint.step2.description3"
                                )
                              )
                            )
                          ])
                        ])
                      ]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "py-0 text-center",
                        attrs: { cols: "12", "align-self": "center" }
                      },
                      [
                        _vm.disableCashback
                          ? _c("div", { staticClass: "primary--text" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "forms.CalculationRuleForm.in_out_hint.step3_disable_cashback.description",
                                      { value: _vm.step3Description() }
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          : _c("div", { staticClass: "primary--text" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "forms.CalculationRuleForm.in_out_hint.step3.description",
                                      { value: _vm.step3Description() }
                                    )
                                  ) +
                                  " "
                              )
                            ])
                      ]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-6 pb-0 pt-md-0", attrs: { cols: "12" } },
                    [
                      _c("v-switch", {
                        attrs: {
                          label: _vm.$t(
                            "forms.CalculationRuleForm.usage_hint.title"
                          )
                        },
                        on: { change: _vm.setLimits },
                        model: {
                          value: _vm.useLimits,
                          callback: function($$v) {
                            _vm.useLimits = $$v
                          },
                          expression: "useLimits"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.useLimits
                    ? [
                        _c(
                          "v-col",
                          { staticClass: "pt-0", attrs: { cols: "12" } },
                          [
                            _c("p", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t(
                                    "forms.CalculationRuleForm.usage_hint.description1"
                                  )
                                )
                              }
                            }),
                            _c("p", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t(
                                    "forms.CalculationRuleForm.usage_hint.description2"
                                  )
                                )
                              }
                            })
                          ]
                        ),
                        _c(
                          "v-col",
                          { staticClass: "py-0", attrs: { cols: "12" } },
                          [
                            _c("cp-text-field", {
                              ref: "usage_limit_field",
                              attrs: {
                                label: _vm.$t(
                                  "forms.CalculationRuleForm.usage_limit.label"
                                ),
                                hint: _vm.$t(
                                  "forms.CalculationRuleForm.usage_limit.hint"
                                ),
                                placeholder: _vm.$t(
                                  "forms.CalculationRuleForm.usage_limit.placeholder"
                                ),
                                suffix: _vm.$t(
                                  "forms.CalculationRuleForm.usage_limit.suffix"
                                ),
                                required: "",
                                "error-messages": _vm.getErrorMessages(
                                  "usage_limit"
                                )
                              },
                              on: {
                                input: function($event) {
                                  return _vm.clearErrorMessages("usage_limit")
                                }
                              },
                              model: {
                                value: _vm.value.usage_limit,
                                callback: function($$v) {
                                  _vm.$set(_vm.value, "usage_limit", $$v)
                                },
                                expression: "value.usage_limit"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "py-0", attrs: { cols: "12" } },
                          [
                            _c("cp-text-field", {
                              ref: "usage_period_field",
                              attrs: {
                                label: _vm.$t(
                                  "forms.CalculationRuleForm.usage_period.label"
                                ),
                                hint: _vm.$t(
                                  "forms.CalculationRuleForm.usage_period.hint"
                                ),
                                placeholder: _vm.$t(
                                  "forms.CalculationRuleForm.usage_period.placeholder"
                                ),
                                suffix: _vm.$t(
                                  "forms.CalculationRuleForm.usage_period.suffix"
                                ),
                                required: "",
                                "error-messages": _vm.getErrorMessages(
                                  "usage_period"
                                )
                              },
                              on: {
                                input: function($event) {
                                  return _vm.clearErrorMessages("usage_period")
                                },
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.$emit("triggerSubmit")
                                }
                              },
                              model: {
                                value: _vm.value.usage_period,
                                callback: function($$v) {
                                  _vm.$set(_vm.value, "usage_period", $$v)
                                },
                                expression: "value.usage_period"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._t("default"),
      _vm._t("submit")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }