var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c("cp-text-field", {
                ref: "name_field",
                attrs: {
                  autofocus: "",
                  label: _vm.$t("forms.TypeForm.name.label"),
                  hint: _vm.$t("forms.TypeForm.name.hint"),
                  placeholder: _vm.$t("forms.TypeForm.name.placeholder"),
                  required: "",
                  type: "text",
                  "error-messages": _vm.getErrorMessages("name")
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("name")
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    _vm.formType == "EDIT" && _vm.$emit("triggerSubmit")
                  }
                },
                model: {
                  value: _vm.value.name,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "name", $$v)
                  },
                  expression: "value.name"
                }
              })
            ],
            1
          ),
          _vm.formType == "CREATE"
            ? _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c("cp-text-field", {
                    ref: "key_field",
                    attrs: {
                      label: _vm.$t("forms.TypeForm.key.label"),
                      hint: _vm.$t("forms.TypeForm.key.hint"),
                      placeholder: _vm.$t("forms.TypeForm.key.placeholder"),
                      required: "",
                      type: "text",
                      "error-messages": _vm.getErrorMessages("key")
                    },
                    on: {
                      input: function($event) {
                        return _vm.clearErrorMessages("key")
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.$emit("triggerSubmit")
                      }
                    },
                    model: {
                      value: _vm.value.key,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "key", $$v)
                      },
                      expression: "value.key"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._t("default"),
      _vm._t("submit")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }