<template>
    <BaseModal ref="modal"
        @cancel="cancel" @confirm="triggerSubmit"
        v-model="isModalOpen"
        :title="title"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
        max-width="570px"
        persistent
    >
        <template v-if="!loading">
            <TypeForm
                ref="type_form"
                v-if="isModalOpen"
                v-model="type"
                :isFormValid.sync="isTypeFormValid"
                :formType="'EDIT'"
                @submit="submitType"
                @triggerSubmit="triggerSubmit"
                :loading="loading"
            />

            <v-divider class="mb-4"></v-divider>
            <span class="primary--text">{{ $t('views.types.personalization') }}</span>

            <CalculationRuleForm
                ref="calculation_rule_form"
                v-if="isModalOpen && calculationRule"
                class="mt-4"
                v-model="calculationRule"
                :isFormValid.sync="isCLRFormValid"
                @submit="getCLR"
                @triggerSubmit="triggerSubmit"
                :formType="'EDIT'"
                :loading="loading"
            />
            <v-row v-else>
                <v-col cols="12">
                    <v-btn width="100%" depressed outlined color="primary" class="mt-4 pt-1" @click="editCalculationRule">
                        {{ $t('views.types.Details.edit_calculation_rule') }}
                    </v-btn>
                    <div class="mt-4">{{ $t('views.types.Details.no_calculation_rule') }}</div>
                </v-col>
            </v-row>
        </template>
        <span v-else>{{ $t('forms.wait') }}</span>
    </BaseModal>
</template>

<script>
import CalculationRuleForm from '@/components/forms/CalculationRuleForm'
import BaseModal           from '@/components/modals/BaseModal'
import TypeForm            from '@/components/forms/TypeForm'
import CalculationRule     from '@/models/CalculationRule'
import BaseModalMixin      from '../BaseModalMixin'
import Type                from '@/models/Type'

export default {
    name: 'TypesEditModal',
    mixins: [ BaseModalMixin ],
    components: { BaseModal, TypeForm, CalculationRuleForm },
    props: {
        type: {
            type: Type,
            required: true,
        },
        calculationRule: {
            validation: value => {
                return !value || value.instanceof(CalculationRule)
            },
        },
    },
    data: vm => {
        return {
            CLRFromModel: null,

            createdType: null,
            isTypeFormValid: false,
            isCLRFormValid: false,
            hasError: false,
            title: vm.$t('views.types.Edit.title'),
            loading: false,
        }
    },
    methods: {
        editCalculationRule() {
            this.$emit('editCLR', this.type)
        },
        triggerSubmit() {
            // Atribui valores para passar na validação. Caso a regra de cálculo for ser submetida, esses valores serão sobrescritos
            if (this.calculationRule) {
                this.calculationRule.type_id = 'just_for_validation'
                this.calculationRule.type_name = 'just_for_validation'

                // Precisa saber desde já se está tudo certo com a validação da regra de cálculo
                this.$refs.calculation_rule_form.submit()
            }

            this.$refs.type_form.submit()
        },
        getCLR(calculationRule) {
            this.CLRFromModel = calculationRule
        },
        async submitType(type) {
            // Precisa garantir já aqui que a regra de cálculo também é válida
            if (!this.isTypeFormValid || (this.calculationRule && !this.isCLRFormValid))
                return

            this.loading = true

            let errType
            let response = await type.update()
                .catch(err => {
                    if (err.message == 'Network Error') {
                        errType = 'network_error'
                        return 
                    }

                    if (err.response.data.type) {
                        errType = err.response.data.type
                        return
                    }

                    errType = 'error'
                })
                
            if (errType) {
                this.loading = false
                const definedErrors = [
                    'network_error'
                ]
                errType = definedErrors.includes(errType) ? errType : 'error'
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t(`views.types.Edit.alerts.${errType}`), 'error', resolve)
                })
                return
            }

            await this.$store.dispatch('type/fetch')

            // Somente se tudo correu bem submete o formulário da regra de cálculo
            if (this.calculationRule) {
                let typeModel = new Type(response.data) 

                this.CLRFromModel.type_id = typeModel.id
                this.CLRFromModel.type_name = typeModel.name

                // Somente se tudo correu bem submete o formulário da regra de cálculo
                this.submitCalculationRule(this.CLRFromModel)
            } else {
                this.loading = false
                await this.confirm()
            }
        },
        async submitCalculationRule(calculationRule) {
            let errType
            // Caso tipo ainda não tenha regra de cálculo, é preciso criá-la
            if (calculationRule.id) {
                let response = await calculationRule.update()
                    .catch(err => {
                        if (err.message == 'Network Error') {
                            errType = 'network_error'
                            return 
                        }

                        if (err.response && err.response.data.type) {
                            errType = err.response.data.type
                            return
                        }

                        errType = 'error'
                    })
            } else {
                let response = await calculationRule.create()
                    .catch(err => {
                        if (err.message == 'Network Error') {
                            errType = 'network_error'
                            return 
                        }

                        if (err.response && err.response.data.type) {
                            errType = err.response.data.type
                            return
                        }

                        errType = 'error'
                    })
            }

            this.loading = false

            if (errType) {
                const definedErrors = [
                    'network_error'
                ]
                errType = definedErrors.includes(errType) ? errType : 'error'
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t(`views.calculationRules.Edit.alerts.${errType}`), 'error', resolve)
                })
            }

            await this.confirm()
        },
        async beforeConfirm(type) {
            this.resolveValue = { type: this.type, calculationRule: this.calculationRule }
            return true
        },
    },
    computed: {
        computedConfirmButton() {
            return {
                label: this.$t('actions.save'),
                disabled: !this.isTypeFormValid || (this.calculationRule && !this.isCLRFormValid),
                loading: this.loading,
            }
        },
        computedCancelButton() {
            return {
                disabled: this.loading,
            }
        },
    },
}
</script>